import React from "react"
import styled from '@emotion/styled'
import UseAnimations from "react-useanimations";
import arrowRightCircle from 'react-useanimations/lib/arrowRightCircle'
import lock from 'react-useanimations/lib/lock'
import airplay from 'react-useanimations/lib/airplay'
import alertTriangle from 'react-useanimations/lib/alertTriangle'
import loading3 from 'react-useanimations/lib/loading3'
import edit from 'react-useanimations/lib/edit'
import explore from 'react-useanimations/lib/explore'
import activity from 'react-useanimations/lib/activity'
import mail from 'react-useanimations/lib/mail'
import maximizeMinimize2 from 'react-useanimations/lib/maximizeMinimize2'
import copy from 'react-useanimations/lib/copy'
import Fade from 'react-reveal/Fade'

const Button = styled.button`
 
  height:50px;
  width:5px;

  background-color: #secondary;
  font-size: 1.25em;
  display:flex;
  justify-content:space-around;
  align-items:center;
  border-radius: 4px ;
  cursor: pointer;
  border: none;
  color: #F2F3F4;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.50;
  text-decoration: none;
  font-weight: 500;
  opacity:0.5;
  margin-right: 25rem;
  margin-top:3rem;
  margin-bottom:3rem;

  &:hover {
     opacity: 1;
    transition: ease-in-out 0.2s;
    background-color: #F5DF4D;
    color: #F2F3F4;
  }
`

const HIRE = () => {
  return (
    <div className="projects">
      <ProjectHeader />
      <Dribbble />
    </div>
  )
}



const ProjectHeader = () => {
  return (
    <div className="project-header">
      <div className="container">
        <div className="project-inner">
        <Fade top delay={30}>
          <div className="project-title">
            <h5>Line of business..</h5>
            </div>
          <div className="project-description">
            <p>
            One of my areas of expertise is finance, management, influencers, architecture, business and the medical field.
           
            </p>
            </div>
           
            </Fade>
         
        </div>
     
        <Fade bottom delay={80}>
        <div className="business-line">
 
        <div className="project-description-line">
         
   
        
        <Button>
          <a className="lines" href="https://www.cryptocurrency.paaolms.com">
          <UseAnimations animation={lock} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4"style={{}} />
          Cryptocurrency
          </a>
          </Button>
          <Button>
          <a className="lines" href="http://www.rentalcar.paaolms.com">
          <UseAnimations animation={alertTriangle} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4"style={{}} />
          Transport
          </a>
          </Button>
          <Button>
          <a className="lines" href="https://www.architecture.paaolms.com">
          <UseAnimations animation={loading3} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4"style={{}} />
          Architecture
          </a>
          </Button>
          <Button>
          <a className="lines" href="https://www.saas.paaolms.com">
          <UseAnimations animation={edit} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4" style={{}} />
          Saas
          </a>
          </Button>
          <Button>
          <a className="lines" href="https://www.ebusiness.paaolms.com">
          <UseAnimations animation={copy} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4"style={{}} />
          eBusiness
          </a>
          </Button>
          <Button>
          <a className="lines" href="https://www.index.paaolms.com">
          <UseAnimations animation={airplay} size={32} autoplay={true} loop={true} speed={1} strokeColor="#F2F3F4"style={{}} />
          Application
          </a>
          </Button>
          <Button>
          <a className="lines" href="https://www.index.paaolms.com">
          <UseAnimations animation={activity} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4"style={{}} />
          Healthcare
          </a>
          </Button>
          <Button>
          <a className="lines" href="https://www.index.paaolms.com">
          <UseAnimations animation={mail} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4"style={{}} />
          Agency
          </a>
          </Button>
          <Button>
          <a className="lines" href="https://www.index.paaolms.com">
          <UseAnimations animation={explore} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4"style={{}} />
          Charity
          </a>
          </Button>
          <Button>
          <a className="lines"href="https://www.index.paaolms.com">
          <UseAnimations animation={maximizeMinimize2} size={32} autoplay={true} loop={true} speed={0.5} strokeColor="#F2F3F4"style={{}} />
          AR
          </a>
          </Button>
         </div>
         </div>
         </Fade>
         </div>
      </div>
        
    )
  }



const Dribbble = () => {
  return (
    <div className="dribble-showcase">
      <div className="container">
        <div className="dribble-showcase-inner">
          <h4>Check out my Github</h4>
          <span>
          <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://github.com/designlense"
              className="drib"
            >
           
             Public Projects<UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.2} strokeColor="#F2F3F4"style={{}} />
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default HIRE
